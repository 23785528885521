<template>
  <div class="chart">
    <svg ref="svg" :height="height" :width="850">
      <g ref="chartGroup"></g>
    </svg>
  </div>
</template>

<script>
import { ref, onMounted, watch, onUpdated } from 'vue';

export default {
  name: 'LineChart',
  props: {
    data: {
      type: Object,
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
    },
    height: {
      type: Number,
    },
  },
  setup(props) {
    const svg = ref(null);
    const chartGroup = ref(null);
    const chartData = ref(null);

    const updateChart = () => {
      if (!chartGroup.value || !chartData.value) {
        return;
      }

      const svgWidth = svg.value.clientWidth;
      const svgHeight = svg.value.clientHeight;

      const margin = 30;
      const chartWidth = svgWidth - margin * 2;
      const chartHeight = svgHeight - margin * 2;
      const max = chartData.value.max > 0 ? chartData.value.max : 1;

      chartGroup.value.innerHTML = ''; // Очистка предыдущего содержимого графика

      // Draw lines
      for (let i = 0; i < chartData.value.datasets.length; i++) {
        const dataset = chartData.value.datasets[i];
        const color = props.colors[i];

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        let d = `M`;
        for (let j = 0; j < dataset.data.length; j++) {
          const dataPoint = dataset.data[j];
          const x = margin + (j / (dataset.data.length - 1)) * chartWidth;
          const y = margin + chartHeight - (dataPoint / max) * chartHeight;
          if (j === 0) {
            d += `${x},${y}`;
          } else {
            d += ` L ${x},${y}`;
          }
        }
        path.setAttribute('d', d);
        path.setAttribute('stroke', color);
        path.setAttribute('stroke-width', '6');
        path.setAttribute('fill', 'none');
        chartGroup.value.appendChild(path);
      }

      // Draw circles on data points
      for (let i = 0; i < chartData.value.datasets.length; i++) {
        const dataset = chartData.value.datasets[i];
        const color = props.colors[i];

        for (let j = 0; j < dataset.data.length; j++) {
          const dataPoint = dataset.data[j];
          const x = margin + (j / (dataset.data.length - 1)) * chartWidth;
          const y = margin + chartHeight - (dataPoint / max) * chartHeight;

          const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');
          circle.setAttribute('cx', x);
          circle.setAttribute('cy', y);
          circle.setAttribute('r', '4');
          circle.setAttribute('stroke', color);
          circle.setAttribute('stroke-width', '2');
          circle.setAttribute('fill', '#02130E');
          chartGroup.value.appendChild(circle);

          if (dataPoint !== 0) {
            const label = document.createElementNS('http://www.w3.org/2000/svg', 'text');
            label.setAttribute('x', x);
            label.setAttribute('y', y - 10);
            label.setAttribute('fill', color);
            label.setAttribute('font-family', 'Arial');
            label.setAttribute('font-size', '12px');
            label.setAttribute('font-weight', '600');
            label.setAttribute('text-anchor', 'middle');
            label.textContent = dataPoint.toLocaleString();
            chartGroup.value.appendChild(label);
          }
        }
      }

      // Draw labels
      for (let i = 0; i < chartData.value.labels.length; i++) {
        const label = chartData.value.labels[i];
        const x = margin + (i / (chartData.value.labels.length - 1)) * chartWidth;
        const y = margin + chartHeight + 20;

        const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
        text.setAttribute('x', x);
        text.setAttribute('y', y);
        text.setAttribute('fill', '#ABD3EC');
        text.setAttribute('font-family', 'Arial');
        text.setAttribute('font-size', '12px');
        text.setAttribute('text-anchor', 'middle');
        text.textContent = label;
        chartGroup.value.appendChild(text);
      }
    };

    onMounted(() => {
      chartData.value = props.data;

      // Calculate max value for scaling
      let max = 0;
      for (let i = 0; i < chartData.value.datasets.length; i++) {
        const dataset = chartData.value.datasets[i];
        const datasetMax = Math.max(...dataset.data);
        if (datasetMax > max) {
          max = datasetMax;
        }
      }
      chartData.value.max = max;

      updateChart();
    });

    onUpdated(() => {
      updateChart();
    });

    watch(
      () => props.data,
      (newData) => {
        console.log('New data received', newData);
        chartData.value = newData;
        updateChart();
      }
    );

    return {
      svg,
      chartGroup,
    };
  },
};
</script>

<style>
.chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  max-width: 100%;
  max-height: 100%;
}
</style>
