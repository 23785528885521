<template>
    <div class="flex justify-between">
        <div class="border-[1px] border-[#02130E] w-[67%] px-[21px] py-[17px] rounded-[10px] mt-[27px]">
            <div class="uppercase bg-[#D8E2DC] text-[13px] py-[4px] font-bold text-center rounded-[4px]">
                конструктор тарифів
            </div>

            <div class="w-full">
                <table class="w-full">
                    <thead>
                        <tr class="text-white">
                            <th class="text-[14px] w-[10%] text-start"></th>
                            <th class="text-[14px] w-[10%] text-start"></th>
                            <th class="text-[14px] w-[10%] text-start"></th>
                            <th class="text-[14px] w-[10%] text-start"></th>
                            <th class="text-[14px] w-[10%] text-start"></th>
                            <th class="text-[14px] w-[10%] text-start"></th>
                            <th class="text-[14px] w-[40%]"></th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <tariff-element 
                            v-for = "tariff in allTariffs"
                            :tariff = "tariff"
                            :allRoles = "allRoles"
                            :key="tariffsKey"
                            @delete="deleteTariff(tariff.id)"
                            @update="ctx => updateTariff(ctx)"
                        />

                        <tr class="text-white w-full  h-[50px] border-b-[1px] border-[#252526]">
                            <td class="w-[13%] px-[2px] text-start py-1 text-[0.9rem]">
                                <input v-model="newTariff.name" type="text" class="w-full text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="Назва тарифу...">
                            </td>
                            <td class="w-[17%] text-start py-1 text-[0.9rem]">
                                <div class="flex w-full text-[#5AC47D]">
                                    <input v-model="newTariff.price" type="number" class="w-[65%] px-[4px] text-[0.9rem] bg-transparent font-semibold text-center text-[#5AC47D] placeholder:text-[#5AC47D] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0.00">
                                    <span>грн.</span>
                                </div>
                            </td>
                            <td class="w-[19%] px-[2px] text-start py-1 text-[0.9rem]">
                                <!-- <input type="text" class="w-full text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="Нова роль..."> -->
                                
                                <select :value="newTariff.role_id" @change="event => setRole(event)" class="w-full text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none">
                                    <option class="bg-[#252526]" value="">Без ролі</option>
                                    <option v-for="role in allRoles" class="bg-[#252526]" :value="role.id">{{ role.name }}</option>
                                </select>
                            </td>
                            <td class="w-[13%] px-[15px] text-start py-1 text-[0.9rem]">
                                <div class="flex">
                                    <input v-model="newTariff.month" type="number" class="w-full text-center text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
                                    <span>міс.</span>
                                </div>
                            </td>
                            <td class="w-[13%] px-[15px] text-start py-1 text-[0.9rem]">
                                <div class="flex">
                                    <input v-model="newTariff.week" type="number" class="w-full text-center text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
                                    <span>тиж.</span>
                                </div>
                            </td>
                            <td class="w-[13%] px-[15px] text-start py-1 text-[0.9rem]">
                                <div class="flex">
                                    <input v-model="newTariff.day" type="number" class="w-full text-center text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
                                    <span>дн.</span>
                                </div>
                            </td>
                            <td class="w-[12%] h-full">
                                <div class="flex justify-end items-center">
                                    <img @click="addTariff" src="@/assets/icons/green_check_point.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                                    <img @click="clearNewTariff" src="@/assets/icons/trash_icon.svg" class="cursor-pointer ">
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
        <div class="border-[1px] border-[#02130E] w-[30%] px-[21px] py-[17px] rounded-[10px] mt-[27px]">
            <div class="uppercase bg-[#D8E2DC] text-[13px] py-[4px] font-bold text-center rounded-[4px]">
                ролі тарифів
            </div>

            <div class="w-full">

                <table class="w-full">
                    <thead>
                        <tr class="text-white">
                            <th class="text-[14px]  text-start"></th>
                            <th class="text-[14px] "></th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <tariff-role-element 
                            v-for="role in allRoles"
                            :key="role.id"
                            :roleName="role.name"
                            :roleId="role.id"
                            @deleteRole="deleteRole"
                            @updateRole="ctx => updateRole(ctx)"
                        />

                        <tr class="text-white w-full h-[50px] border-b-[1px] border-[#252526]">
                            <td class=" text-start py-1 text-[0.9rem]">
                                <input v-model="newRole" type="text" class="text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="Нова роль...">
                            </td>
                            <td class="h-full">
                                <div class="flex justify-end items-center">
                                    <img @click="addNewRole" src="@/assets/icons/green_check_point.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                                    <img @click="newRole = ''" src="@/assets/icons/trash_icon.svg" class="cursor-pointer w-[24px] h-[24px]">
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import TariffRoleElement from './TariffRoleElement.vue'
    import TariffElement from './TariffElement.vue'
    import { uuid } from 'vue3-uuid'
    import { addTariffRoleRequest, deleteTariffRoleRequest, updateTariffRoleRequest, getAllTariffRolesRequest, addTariffRequest, getAllTariffsRequest, updateTariffRequest, deleteTariffRequest } from '@/services/apiRequests'

    export default {
        name: 'tariff-constructor',
        setup(props, { emit }) {
            const newRole = ref('')

            const allRoles = ref([])
            const allTariffs = ref([])

            const tariffsKey = ref(uuid.v4())

            const newTariff = ref({
                name: '',
                price: null,
                role_name: '',
                role_id: '',
                month: null,
                week: null,
                day: null
            })

            const clearNewTariff = () => {
                newTariff.value = {
                    name: '',
                    price: null,
                    role_name: '',
                    role_id: '',
                    month: null,
                    week: null,
                    day: null
                }
            } 

            onMounted(async () => {
                const allTariffRoles = await getAllTariffRolesRequest()
                console.log('allTariffRoles.data ----- ', allTariffRoles)
                allRoles.value = allTariffRoles
                
                const allTariffsRequest = await getAllTariffsRequest()
                console.log('allTariffsRequest.data ----- ', allTariffsRequest)
                allTariffs.value = allTariffsRequest
            })

            const setRole = (event) => {
                newTariff.value.role_id = event.target.value
                newTariff.value.role_name = allRoles.value.find(role => role.id === event.target.value).name
            }

            const addTariff = () => {
                newTariff.value.id = uuid.v4()
                console.log('newTariff.value --------- ', newTariff.value)
                allTariffs.value.push(newTariff.value)
                addTariffRequest(newTariff.value)
                clearNewTariff()
            }

            const deleteTariff = (id) => {
                deleteTariffRequest(id)
                allTariffs.value = allTariffs.value.filter(tariff => tariff.id !== id)
            }

            const updateTariff = (ctx) => {
                updateTariffRequest(ctx)
                console.log('ctx ---- ', ctx)
                allTariffs.value = allTariffs.value.map(tariff => {
                    if (tariff.id === ctx.id) {
                        tariff.name = ctx.name
                        tariff.price = ctx.price
                        tariff.role_name = ctx.role_name
                        tariff.role_id = ctx.role_id
                        tariff.month = ctx.month
                        tariff.week = ctx.week
                        tariff.day = ctx.day
                    }
                    return tariff
                })
            }

            const addNewRole = () => {
                const newRoleId = uuid.v4()
                allRoles.value.push({
                    id: newRoleId,
                    name: newRole.value
                })

                addTariffRoleRequest({
                    id: newRoleId,
                    name: newRole.value
                })

                newRole.value = ''
            }

            const deleteRole = (id) => {
                console.log('id ---- ', id)

                allRoles.value = allRoles.value.filter(role => role.id !== id)

                allTariffs.value = allTariffs.value.map(tariff => {
                    if (tariff.role_id === id) {
                        tariff.role_id = ''
                        tariff.role_name = ''
                    }
                    return tariff
                })

                deleteTariffRoleRequest(id).then(res => console.log('res ---- ', res))

                tariffsKey.value = uuid.v4()
            }

            const updateRole = (ctx) => {
                allRoles.value = allRoles.value.map(role => {
                    if (role.id === ctx.id) {
                        role.name = ctx.name
                    }
                    return role
                })

                allTariffs.value = allTariffs.value.map(tariff => {
                    if (tariff.role_id === ctx.id) {
                        tariff.role_name = ctx.name
                    }
                    return tariff
                })

                updateTariffRoleRequest(ctx)

                tariffsKey.value = uuid.v4()
            }

            return {
                newRole,
                allRoles,
                addNewRole,
                deleteRole,
                updateRole,
                allTariffs,
                addTariff,
                deleteTariff,
                updateTariff,
                newTariff,
                setRole,
                clearNewTariff,
                tariffsKey
            }
        },

        components: {
            TariffRoleElement,
            TariffElement
        }
    }
</script>

<style lang="scss" scoped>

</style>