<template>
    <div style="background-color: rgba(29, 29, 29, .35)" class="backdrop-blur-[2px] top-[-70px] fixed w-full h-[130%] -mt-2 z-40"></div>
    <!-- <coach-selection-form v-if="selectTrainerFlag" @selectTrainer="trainer => setTrainer(trainer)" class="z-[60]"/> -->
    <div style="box-shadow: 4px 4px 4px 0px #00000040;" class="border-[1px] border-[#8C8C8C33] bg-[#252526] pt-[23px] rounded-[10px] px-[15px] w-[291px] h-[776px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] ml-[100px] z-50">

        <div class="flex justify-center mb-[15px]"><img src="../../../assets/logo.svg" class="h-[70px]"></div>
        <div class="font-semibold text-center px-[20px] text-[16px] text-[#fff] leading-[19px] mb-[10px]">Створення групи та призначення тренера</div>

        <div class="w-full">
            <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                назва нової групи
            </div>
            <div class="mt-[3px]">
                <input 
                    v-model="groupName"
                    class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[3px] pl-[5px] outline-none"
                    type="text" 
                    name="name"
                >
            </div>
        </div>
        
        <div class="w-full mt-[20px]">
            <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                діапазон років народження учнів
            </div>
            <div class="mt-[3px]">
                <input 
                    v-model="groupPeriods"
                    class="w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[3px] pl-[5px] outline-none"
                    type="text" 
                    name="name"
                >
            </div>
        </div>

        <div class="w-full mt-[20px]">
            <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                оберіть тренера зі списку
            </div>
            <select :value="trainerId" @change="e => setTrainer(e.target.value)" class="mt-[3px] w-full border-[1px] border-[#9E9E9E] rounded-[6px] text-[#fff] bg-[#474849] bg-opacity-20 text-[13px] py-[5.5px] pl-[5px] outline-none" name="to_balance">
                <option class="bg-[#252526] text-[#fff]" value="" disabled>Виберіть тренера</option>
                <option v-for="trainer in trainers" :value="trainer.trainer_id" class="bg-[#252526] text-[#fff]">{{ trainer.name + ' ' + trainer.surname }}</option>
            </select>
        </div>

        <div class="w-full mt-[20px] flex items-center justify-between">
            <div class="text-[#8C8C8C] text-center font-medium relative top-[5px] text-[14px]">
                Група 
                <span v-if="groupPaymentStatusBool" class="text-[#5AC47D]">платна</span>
                <span v-else class="text-[#FA1367]">безоплатна</span>
            </div>
            <div>
                <IphoneSwitch :isOnValue="groupPaymentStatusBool" @change="changeGroupPaymentStatus"/>
            </div>
        </div>

        <div class="relative mx-auto h-[234px] border-[1px] border-[#9E9E9E] mt-[20px] rounded-[6px]">
            <div v-if="selectTrainerFlag" class="w-full h-full relative">
                <div class="flex justify-center items-center h-full">
                    <div class="text-[#8C8C8C] text-center font-medium text-[14px]">
                        інформація про тренера
                    </div>
                </div>
                <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] top-[40px] rotate-[45deg]"></div>
                <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] bottom-[40px] right-0 rotate-[45deg]"></div>
                <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] bottom-[40px] left-0 rotate-[-45deg]"></div>
                <div class="absolute w-[80px] h-[1px] bg-[#8C8C8C] top-[40px] right-0 rotate-[-45deg]"></div>
            </div>

            <div v-else class="absolute w-full bottom-0">
                <div class="h-[150px] w-full flex justify-center -z-10">
                    <img :src="apiURL + 'static/' + selectedTrainer.photo" @error="$event.target.src = require('@/assets/photo-placeholder-big.png')" class="-z-10 relative ">
                </div>
                <div class="bg-[#5AC47D] w-full py-1 mt-[-20px] z-20 text-[#02130E]">
                    <div class="font-medium text-[23px] text-center">{{ selectedTrainer.name ? selectedTrainer.name + ' ' + selectedTrainer.surname : "Тренер" }}</div>
                    <div class="font-medium text-[23px] relative bottom-[3px] text-center">{{ groupName.length > 0 ? groupName : 'Група' }}</div>
                    <div class="text-center">({{ groupPeriods ? groupPeriods : "? - ?" }})</div>
                </div>
            </div>
        </div>

        <button @click="saveGroup" class="w-full rounded-[6px] bg-[#5AC47D] text-[13px] text-[#02130E] py-[6px] mt-[20px]">
            Створити
        </button>

        <button @click="$emit('close')" class="w-full rounded-[6px] bg-[#ABD3EC] text-[#02130E] text-[13px] py-[6px] mt-[17px]">
            Відмінити
        </button>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { addNewGroup, getAllTrainers } from '@/services/apiRequests'

    import DataInput from '../ui/DataInput.vue'
    import CoachSelectionForm from '../ui/CoachSelectionForm.vue'
    import IphoneSwitch from '../ui/IphoneSwitch.vue'

    export default {
        name: 'new-group-form',
        
        components: {
            DataInput,
            CoachSelectionForm,
            IphoneSwitch
        },

        setup(props, { emit }) {
            const selectedTrainer = ref({})
            const selectTrainerFlag = ref(true)
            const groupName = ref('')
            const groupPeriods = ref('')
            const apiURL = ref(process.env.VUE_APP_API_URL)
            const trainers = ref([])
            const trainerId = ref('')

            const groupPaymentStatusBool = ref(true)
            const groupPaymentStatusText = ref('paid')

            const changeGroupPaymentStatus = () => {
                groupPaymentStatusBool.value = !groupPaymentStatusBool.value
                if (groupPaymentStatusBool.value) {
                    groupPaymentStatusText.value = 'paid'
                } else {
                    groupPaymentStatusText.value = 'free'
                }
            }

            const setTrainer = (trainer_id) => {
                trainerId.value = trainer_id
                selectedTrainer.value = trainers.value.find(trainer => trainer.trainer_id == trainer_id)
                selectTrainerFlag.value = false
            }

            onMounted(async () => {
                const response = await getAllTrainers()
                response.data.forEach(trainer => {
                    trainers.value.push({
                        trainer_id: trainer.trainer_id,
                        name: trainer.name,
                        surname: trainer.surname,
                        photo: trainer.photo
                    })
                })
            })

            const saveGroup = () => {
                addNewGroup(groupName.value, groupPeriods.value, selectedTrainer.value.trainer_id, groupPaymentStatusText.value)
                    .then(res => {
                        emit('close')
                        emit('updateGroups')
                    })
            }

            return {
                selectedTrainer,
                selectTrainerFlag,
                setTrainer,
                apiURL,
                groupName,
                groupPeriods,
                saveGroup,
                trainers,
                trainerId,
                groupPaymentStatusBool,
                groupPaymentStatusText,
                changeGroupPaymentStatus
            }
        }
    }
</script>
