<template>
    <div class="chart flex justify-center w-max">
      <canvas ref="canvas" height="35" width="340"></canvas>
    </div>
  </template>


<script>
  import { ref, onMounted, watch } from 'vue';
  
  export default {
    name: 'MinLineChart',
    props: {
      data: {
        type: Object,
        required: true,
      },
    },
    setup(props) {
      const canvas = ref(null);
      const ctx = ref(null);
  
      const updateChart = () => {
        if (!ctx.value) {
          return;
        }
        const margin = 30

        const chartWidth = canvas.value.width - margin * 1.3;
        const chartHeight = canvas.value.height;

        const max = Math.max(...props.data) > 0 ? Math.max(...props.data) : 10;
        ctx.value.strokeStyle = '#ABD3EC';
        ctx.value.beginPath();
        for (let j = 0; j < props.data.length; j++) {
            console.log(j)
            const dataPoint = props.data[j];
            const x = margin + (j / (props.data.length - 1)) * chartWidth;
            const y = (chartHeight - (dataPoint / max) * chartHeight * 0.2) - 5;
            ctx.value.lineWidth = 4;
            if (j === 0) {
                ctx.value.moveTo(x, y);
            } else {
                ctx.value.lineTo(x, y);
            }
        }
        ctx.value.stroke();

        ctx.value.beginPath();
        for (let j = 0; j < props.data.length; j++) {
            const dataPoint = props.data[j];
            const x = margin + (j / (props.data.length - 1)) * chartWidth;
            const y = (chartHeight - (dataPoint / max) * chartHeight * 0.2) - 5;

            // if (dataPoint > 0) {
            ctx.value.fillStyle = '#ABD3EC';
            ctx.value.textAlign = 'center'; 
            ctx.value.font = 'bold 12px Arial';
            const labelX = x;
            const labelY = y - 10;
            const labelText = dataPoint.toLocaleString();
            ctx.value.fillText(labelText, labelX, labelY);
            // }
        }
  
    };
  
    onMounted(() => {
        ctx.value = canvas.value.getContext('2d');
        updateChart();
    });


    return {
        canvas,
    };
    
    },
};
</script>