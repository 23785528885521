<template>
    <tr class="text-white w-full  h-[50px] border-b-[1px] border-[#252526]">
        <td class=" text-start py-1 text-[0.9rem]">
            <div v-if="!isEdit">
                {{ roleNameRef }}
            </div>
            <input v-else v-model="roleNameRef" type="text" class="text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="Нова роль...">
        </td>
        <td class="h-full">
            <div class="flex justify-end items-center">
                <img v-if="!isEdit" @click="isEdit = true" src="@/assets/icons/pen_icon.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                <img v-else @click="editRole" src="@/assets/icons/green_check_point.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                <img @click="deleteRole" src="@/assets/icons/trash_icon.svg" class="cursor-pointer w-[24px] h-[24px] ">
            </div>
        </td>
    </tr>
</template>

<script>
    import { updateCategoryById } from '@/services/apiRequests'
    import { ref, onMounted } from 'vue'

    export default {
        name: 'category-element',

        props: {
            roleName: {
                type: String,
                required: true
            },
            roleId: {
                type: String,
                required: true
            },
        },

        setup(props, { emit }) {

            const roleNameRef = ref(props.roleName)
            const isEdit = ref(false)

            const editRole = () => {
                console.log('edit')
                isEdit.value = false
                emit('updateRole', { name: roleNameRef.value, id: props.roleId })
            }

            const deleteRole = () => {
                emit('deleteRole', props.roleId)
            }

            return {
                roleNameRef,
                isEdit,
                editRole,
                deleteRole
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>