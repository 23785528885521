<template>
    <div class="fixed shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] top-[50%] left-[50%] bg-[#02130E] p-4 rounded-[10px] mt-[-20px] w-[250px]">
        <div class="text-center mb-3 text-[#FFF]">Ви дійсно хочете видалити учня {{ student.name + ' ' + student.surname }}?</div>
        <div class="flex justify-between">
            <button @click="deleteStudent" class="bg-[#F9245A] hover:bg-opacity-90 text-white px-4 py-1 rounded-[10px]">Так</button>
            <button @click="$emit('close')" class="px-4 py-1 rounded-[10px] bg-[#ABD3EC]">Ні</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'delete-student-form',

        props: {
            student: {
                type: Object,
                required: true
            }
        },

        setup(props, { emit }) {
            const deleteStudent = () => {
                emit('acceptDeleteStudent', props.student)
            }

            return {
                deleteStudent
            }
        }
    }
</script>
