<template>
    <div class="search-block w-[90%] mx-auto">
        <div class="flex justify-between mx-2 mt-2">
            <div class="search-bar flex items-center bg-[#FFFFFF] border-[1px] border-[#FFF] bg-opacity-30 px-[5px] rounded-[6px]">
                <img src="@/assets/icons/magnify_icon.svg" class="w-3 h-3">
                <input v-model="search" class="text-[#FFFFFF] bg-transparent bg-opacity-30 text-[14px] rounded-md px-1 py-2 placeholder:text-[#FFFFFF] outline-none w-[250px]" placeholder="Пошук..." type="text">
            </div>
            <div class="filter flex items-center">
                <div class="flex w-[110px] justify-between mr-[130px] text-[#FFFFFF]">
                    <img @click="prevPage" src="@/assets/icons/left_arrow.svg" class="cursor-pointer">
                    <div class="flex mx-[20px]">Сторінка: <input v-model="page" type="number" class="bg-transparent mx-2 w-[5ch] outline-none px-1"> / <span class="ml-[10px]">{{ maxPage }}</span></div>
                    <img @click="nextPage" src="@/assets/icons/right_arrow.svg" class="cursor-pointer">
                </div>
                <button class="bg-[#5AC47D] hover:bg-[#5AC47Dd6] w-[228px] text-white px-4 py-[6px] text-[14px] rounded-md" @click="$emit('addStudent')">Додати</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, watch } from 'vue'

    export default {
        name: 'menu-bar',

        emits: ['update:modelValue', 'startFilter'],

        props: {
            modelValue: {
                type: String,
                default: ''
            },
            page: {
                type: Number,
                default: 1
            },
            maxPage: {
                type: Number,
                default: 1
            }
        },

        setup(props, { emit }) {
            const search = ref(props.modelValue)
            const page = ref(props.page) 

            const prevPage = () => {
                page.value > 1 ? page.value-- : page.value = 1
            }

            const nextPage = () => {
                page.value += 1
            }

            watch(search, (value) => {
                emit('update:modelValue', value)
                emit('startFilter')
            })

            watch(page, (value) => {
                if (value >= props.maxPage) {
                    page.value = props.maxPage
                } else if (value <= 1 && value != '') {
                    page.value = 1
                } else if (value == ''){
                    return
                }
                emit('update:page', value)
                emit('startFilter')
            })

            return {
                search,
                page,
                prevPage,
                nextPage
            }
        }
    }
</script>

<style lang="scss" scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
