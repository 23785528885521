<template>
    <div class="px-[27px] pb-[20px]">
        <Header 
            :fcName="clubNameUkr"
            :fcAddress="legalAddress"
            :firstPhone="clubPhone"
            :secondPhone="clubAdditionalPhone"

            :webPage="webPage"
            :facebookPage="facebookPage"
            :instagramPage="instagramPage"

            :bankName="bankName"
            :bankAccount="receiverAccount"
            :receiverId="receiverCode"
            :ERDPOUcode="EDRPOUcode"

            :sinceDate="dateOfFoundationText"
        />

        <GeneralInformation 
            v-model:clubNameUkr="clubNameUkr"
            v-model:clubNameEng="clubNameEng"
            
            :dateOfFoundationText="dateOfFoundationText"
            @update:dateOfFoundationText="changeDateOfFoundation($event)"

            v-model:legalAddress="legalAddress"
            v-model:postAddress="postAddress"
            v-model:linkToMap="linkToMap"
        />
        
        <Contacts 
            v-model:clubPhone="clubPhone"
            v-model:clubAdditionalPhone="clubAdditionalPhone"
            v-model:clubEmail="clubEmail"
            v-model:facebookPage="facebookPage"
            v-model:webPage="webPage"
            v-model:instagramPage="instagramPage"
        />

        <BankDetails 
            v-model:bankName="bankName"
            v-model:EDRPOUcode="EDRPOUcode"
            v-model:receiverCode="receiverCode"
            v-model:receiverAccount="receiverAccount"
            v-model:wayforpayKey="wayforpayKey"
        />

        <TariffConstructor 
        
        />
        <!-- <Documents /> -->

        <div class="mt-[30px] relative flex justify-center">
            <div v-if="changesSavedFlag" class="absolute top-[-30px] text-[#5AC47D]">Зміни збережено!</div>
            <button @click="updateSettings" class="w-[200px] h-[40px] rounded-[6px] bg-[#5AC47D] text-[#000] font-medium text-[14px]">
                Зберегти
            </button>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { getAllSettings, updateSettingsRequest } from '@/services/apiRequests' 

    import BankDetails from "./components/BankDetails.vue"
    import Contacts from "./components/Contacts.vue"
    import Documents from "./components/Documents.vue"
    import GeneralInformation from "./components/GeneralInformation.vue"
    import TariffConstructor from './components/TariffConstructor.vue'
    import Header from "./components/Header.vue"

    export default {
        name: 'settings',

        setup() {
            const clubNameUkr = ref('')
            const clubNameEng = ref('')

            const dateOfFoundationText = ref('')
            const dateOfFoundationDate = ref(new Date())

            const legalAddress = ref('')
            const postAddress = ref('')

            const linkToMap = ref('')

            const clubPhone = ref('')
            const clubAdditionalPhone = ref('')
            const clubEmail = ref('')
            const facebookPage = ref('')
            const webPage = ref('')
            const instagramPage = ref('')

            const bankName = ref('')
            const EDRPOUcode = ref('')
            const receiverCode = ref('')

            const receiverAccount = ref('')
            const wayforpayKey = ref('')
            const changesSavedFlag = ref(false)

            const updateSettings = async () => {
                const settings = {
                    club_name_ukr: clubNameUkr.value,
                    club_name_eng: clubNameEng.value,
                    legal_address: legalAddress.value,
                    post_address: postAddress.value,
                    link_to_map: linkToMap.value,
                    club_phone: clubPhone.value,
                    club_additional_phone: clubAdditionalPhone.value,
                    club_email: clubEmail.value,
                    facebook_page: facebookPage.value,
                    web_page: webPage.value,
                    instagram_page: instagramPage.value,
                    bank_name: bankName.value,
                    EDRPOU_code: EDRPOUcode.value,
                    receiver_code: receiverCode.value,
                    receiver_account: receiverAccount.value,
                    wayforpay_key: wayforpayKey.value,
                    date_of_foundation: dateOfFoundationText.value
                }

                const settingsRes = await updateSettingsRequest(settings)

                changesSavedFlag.value = true
                setTimeout(() => {
                    changesSavedFlag.value = false
                }, 2000)

                console.log('settingsRes ------ ', settingsRes)
            }

            onMounted(async () => {
                const settingsRes = await getAllSettings()

                if (settingsRes) {
                    clubNameUkr.value = settingsRes.club_name_ukr
                    clubNameEng.value = settingsRes.club_name_eng
                    legalAddress.value = settingsRes.legal_address
                    postAddress.value = settingsRes.post_address
                    linkToMap.value = settingsRes.link_to_map
                    clubPhone.value = settingsRes.club_phone
                    clubAdditionalPhone.value = settingsRes.club_additional_phone
                    clubEmail.value = settingsRes.club_email
                    facebookPage.value = settingsRes.facebook_page
                    webPage.value = settingsRes.web_page
                    instagramPage.value = settingsRes.instagram_page
                    bankName.value = settingsRes.bank_name
                    EDRPOUcode.value = settingsRes.EDRPOU_code
                    receiverCode.value = settingsRes.receiver_code
                    receiverAccount.value = settingsRes.receiver_account
                    wayforpayKey.value = settingsRes.wayforpay_key
                    dateOfFoundationText.value = settingsRes.date_of_foundation
                }
                console.log('settingsRes ------ ', settingsRes)
            })

            const changeDateOfFoundation = (date) => {
                dateOfFoundationText.value = date
                dateOfFoundationDate.value = new Date(date)
            }

            return {
                clubNameUkr,
                clubNameEng,
                dateOfFoundationText,
                dateOfFoundationDate,
                legalAddress,
                postAddress,
                linkToMap,
                clubPhone,
                clubAdditionalPhone,
                clubEmail,
                facebookPage,
                webPage,
                instagramPage,
                bankName,
                EDRPOUcode,
                receiverCode,
                receiverAccount,
                wayforpayKey,
                changeDateOfFoundation,
                updateSettings,
                changesSavedFlag
            }
        },

        components: {
            BankDetails,
            Contacts,
            Documents,
            GeneralInformation,
            Header,
            TariffConstructor
        },
    }
</script>

<style lang="scss" scoped>

</style>