<template>
    <tr class="text-white w-full  h-[50px] border-b-[1px] border-[#252526]">
        <td class="w-[13%] px-[2px] text-start py-1 text-[0.9rem]">
            <input v-if="isEdit" v-model="tariffRef.name" type="text" class="w-full text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="Назва тарифу...">
            <div v-else class="truncate">
                {{ tariffRef.name }}
            </div>
        </td>
        <td class="w-[17%] text-start py-1 text-[0.9rem]">
            <div class="w-full flex justify-evenly text-[#5AC47D]">
                <input v-if="isEdit" v-model="tariffRef.price" type="text" class="w-[50%] px-[4px] text-[0.9rem] bg-transparent font-semibold text-center text-[#5AC47D] placeholder:text-[#5AC47D] placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0.00">
                <div v-else class="w-[50%] px-[4px] text-center font-semibold">
                    {{ tariffRef.price }}
                </div>
                <span>грн.</span>
            </div>
        </td>
        <td class="w-[19%] px-[2px] text-start py-1 text-[0.9rem]">
            <!-- <input type="text" class="w-full text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="Нова роль..."> -->
            
            <select v-if="isEdit" :value="tariffRef.role_id" @change="event => setRole(event)" class="w-full text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none">
                <option class="bg-[#252526]" value="">Без ролі</option>
                <option v-for="role in allRoles" class="bg-[#252526]" :value="role.id">{{ role.name }}</option>
            </select>
            <div v-else class="pl-[2px]">
                {{ tariffRef.role_name ? tariffRef.role_name : 'Без ролі' }}
            </div>
        </td>
        <td class="w-[13%] px-[15px] text-start py-1 text-[0.9rem]">
            <div class="flex">
                <input v-if="isEdit" v-model="tariffRef.month" type="text" class="w-full text-center text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
                <div v-else class="w-full text-center">
                    {{ tariffRef.month }}
                </div>
                <span>міс.</span>
            </div>
        </td>
        <td class="w-[13%] px-[15px] text-start py-1 text-[0.9rem]">
            <div class="flex">
                <input v-if="isEdit" v-model="tariffRef.week" type="text" class="w-full text-center text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
                <div v-else class="w-full text-center">
                    {{ tariffRef.week }}
                </div>
                <span>тиж.</span>
            </div>
        </td>
        <td class="w-[13%] px-[15px] text-start py-1 text-[0.9rem]">
            <div class="flex">
                <input v-if="isEdit" v-model="tariffRef.day" type="text" class="w-full text-center text-[0.9rem] bg-transparent text-[#DFF726] placeholder:text-white placeholder:text-[0.9rem] placeholder:opacity-40 relative outline-none" placeholder="0">
                <div v-else class="w-full text-center">
                    {{ tariffRef.day }}
                </div>
                <span>дн.</span>
            </div>
        </td>
        <td class="w-[12%] h-full">
            <div class="flex justify-end items-center">
                <img v-if="isEdit" @click="updateTariff" src="@/assets/icons/green_check_point.svg" class="cursor-pointer w-[24px] h-[24px] mr-[10px]">
                <img v-else @click="isEdit = true" src="@/assets/icons/pen_icon.svg" class="cursor-pointer mr-[10px]">
                <img @click="deleteTariff" src="@/assets/icons/trash_icon.svg" class="cursor-pointer ">
            </div>
        </td>
    </tr>
</template>

<script>
    import { ref } from 'vue'

    export default {
        name: 'tariff-element',

        props: {
            tariff: {
                type: Object,
                required: true
            },
            allRoles: {
                type: Array,
                required: true
            }
        },

        setup(props, { emit }) {
            const tariffRef = ref(Object.assign({}, props.tariff))

            const isEdit = ref(false)

            const setRole = (event) => {
                if (event.target.value === '') {
                    tariffRef.value.role_id = ''
                    tariffRef.value.role_name = ''
                    return
                }
                tariffRef.value.role_id = event.target.value
                tariffRef.value.role_name = props.allRoles.find(role => role.id === event.target.value).name
            }

            const deleteTariff = () => {
                emit('delete')
            }

            const updateTariff = () => {
                emit('update', tariffRef.value)
                isEdit.value = false
            }

            return {
                tariffRef,
                setRole,
                isEdit,
                deleteTariff,
                updateTariff
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>