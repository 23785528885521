<template>
    <div class="bg-[#02130E] w-[400px] h-[305px] rounded-[10px] pt-[10px] mt-[15px] border-[1px] border-[#4b4b4b66]">
        <div class="bg-[#D8E2DC] text-[13px] font-medium text-center rounded-[4px] mx-[14px] py-[1.2px]">
            ФІНАНСИ
        </div>
        <div class="mt-[10px]">
            <div class="flex flex-col items-center">
                <div class="flex justify-between w-full text-[20px] font-bold block-title" :class="{'text-[#5AC47D]' : dateActiveTo > nowDate, 'text-[#F9245A]' : dateActiveTo <= nowDate}">
                    <div v-if="!isEditDate" class="w-full flex pl-[30px] justify-center">
                        <span class="font-['Avenir Cyrillic2']">{{ date_active_to.split('T')[0].split('-').reverse().join('.') }}</span>
                    </div>
                    <div v-else class="w-full flex pl-[30px] justify-center">
                        <input v-model="newDate" class="custom-date-input w-[150px] text-center text-[#fff] bg-transparent outline-none relative left-[14px]" type="date">
                    </div>
                    <img v-if="!isEditDate" @click="isEditDate = true" src="@/assets/icons/pen_icon.svg" class="cursor-pointer mr-[10px]">
                    <img v-else @click="changeActiveToDate" src="@/assets/icons/green_check_point.svg" class="w-[20px] cursor-pointer mr-[10px]">
                </div>
                <div class="text-gray-300 text-[8px] font-light">
                    (дата закінчення тарифу)
                </div>
            </div>
        </div>

        <div class="border-[#8C8C8C] bg-[#474849] bg-opacity-20 mt-[10px] border-[1px] w-[40%] mx-auto items-center flex justify-center relative rounded-[4px] px-[4px]">
            <select :value="currentTarriffRole" @change="e => changeTariffRole(e)" class="bg-transparent text-[#fff] text-[12px] outline-none text-center  placeholder-gray-500 py-[3px] w-full disabled:opacity-40" >
                <option v-for="role in allTariffRoles" :value="role.id" class="bg-[#02130E]">{{ role.name }}</option>
            </select>
        </div>

        <div class="text-white px-[18px] mb-[5px] mt-[20px] text-[14px] text-start font-semibold">
            платежі
        </div>
        <div class="px-[10px] h-[125px] overflow-y-auto scrollbar w-[95%] mx-auto">
            <div v-for="income in allIncomes" class="flex justify-between text-white mb-[7px]">
                <div class="text-[13px] w-[40%]">{{ formateISOtoDateAndTime(income.date) }}</div>
                <div class="text-[13px] w-[40%] text-center font-semibold">{{ income.tariff_name }}</div>
                <div class="text-[13px] w-[20%] text-end text-[#5AC47D]">{{ income.amount - income.prepaid_income}}</div>
                <div class="text-[13px] w-[20%] text-end text-[#DFF726]">{{ income.amount }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    
    import { ref, onMounted, onBeforeMount, watch } from 'vue'
    import { getAllTariffRolesRequest, getAllRealIncomeTariffs } from '@/services/apiRequests'
    export default {
        name: 'new-finance-card',
        props: {
            date_active_to: {
                type: String,
                default: ''
            },
            tariff_role_id: {
                type: String,
                default: ''
            },
            studentId: {
                type: String,
                default: ''
            }
        },

        setup(props, { emit }) {
            const nowDate = ref(new Date())
            const dateActiveTo = ref(new Date(props.date_active_to))

            const isEditDate = ref(false)
            const newDate = ref('')

            const allTariffRoles = ref([])

            const currentTarriffRole = ref(props.tariff_role_id)

            const allIncomes = ref([])

            const formateISOtoDateAndTime = (date) => {
                const dateObj = new Date(date)
                const year = dateObj.getFullYear()
                const month = dateObj.getMonth() + 1 <= 9 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1
                const day = dateObj.getDate() <= 9 ? `0${dateObj.getDate()}` : dateObj.getDate()
                const hours = dateObj.getHours() <= 9 ? `0${dateObj.getHours()}` : dateObj.getHours()
                const minutes = dateObj.getMinutes() <= 9 ? `0${dateObj.getMinutes()}` : dateObj.getMinutes()
                const seconds = dateObj.getSeconds()
                return `${day}.${month}.${year} ${hours}:${minutes}`
            }

            const changeTariffRole = (e) => {
                currentTarriffRole.value = e.target.value
                emit('change-tariff-role', currentTarriffRole.value)
            }

            const changeActiveToDate = () => {
                dateActiveTo.value = new Date(newDate.value)
                emit('change-active-to-date', new Date(newDate.value).toISOString())
                isEditDate.value = false
            }

            onBeforeMount(async () => {
                allTariffRoles.value = await getAllTariffRolesRequest()

                const allIncomesRes = await getAllRealIncomeTariffs(props.studentId)
                console.log('allIncomesRes ----- ', allIncomesRes)
                allIncomes.value = allIncomesRes.transactions
            })

            return {
                nowDate,
                dateActiveTo,
                allTariffRoles,
                isEditDate,
                newDate,
                changeActiveToDate,
                currentTarriffRole,
                changeTariffRole,
                allIncomes,
                formateISOtoDateAndTime
            }
        },

    }
</script>

<style lang="scss" scoped>

/* Скрываем стандартную стрелку в поле ввода */
.custom-date-input::-webkit-calendar-picker-indicator {
    background-image: none;
    cursor: pointer;
}

.scrollbar::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
}

.scrollbar::-webkit-scrollbar-track {
    background: transparent;        /* цвет зоны отслеживания */
}

.scrollbar::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;    /* цвет бегунка */
    border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
}

</style>